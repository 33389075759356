import { Styles } from 'app/theme/materialTheme';

export const outlet: Styles = { mt: 2 };

export const enlargedPhoto: Styles = {
  width: '100%',
};

export const dialog: Styles = {
  width: '100%',
};

export const dialogContent: Styles = {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  p: 1.5,
  flexDirection: 'column',
};

export const dialogTopbar: Styles = {
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const deleteFibersButtonsContainer: Styles = {
  mt: 2,
  justifyContent: 'space-evenly',
  flexDirection: { xs: 'column', sm: 'row' },
  gap: { xs: 1, sm: 2 },
};

export const loaderContainer: Styles = {
  my: 2,
  justifyContent: 'center',
};

export const photoDeleteConfirmationDialog: Styles = {
  mx: 2,
};
export const photo: Styles = {
  cursor: 'pointer',
  borderRadius: 0.5,
  width: 92,
  height: 92,
};
