import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { useTranslation } from 'common/hooks/useTranslation';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { G } from '@mobily/ts-belt';
import { Box, List, ListItem } from '@mui/material';

import { AvailableOutletTypes } from 'app/outlets/AvailiableOutletTypes';
import { useAzureOutletPhotoUpload } from '../../hooks/useAzureOutletPhotoUpload';
import { useOutlets } from '../../hooks/useOutlets';
import { Outlet } from '../../Outlet';
import { ImagePicker } from './components/ImagePicker';
import { TPOutlet } from '../../TPOutlet';
import { useTPOutlets } from '../../hooks/useTPOutlets';
import { OutletTypes } from '../OutletMenu/OutletMenu';
import { PhotoDialogs } from '../../../../common/components/PhotoDialogs/PhotoDialogs';
import { DeletePhotoButton } from '../../../../common/components/DeletePhotoButton/DeletePhotoButton';

export const OutletPhotos: FC<{
  outlet: Outlet | TPOutlet;
  premise: PremiseOnSinglePremise;
  outletType: OutletTypes;
}> = ({ outlet, premise, outletType }) => {
  const { t } = useTranslation();
  const {
    updateOutletPhoto,
    isSuccessDeletePhoto: isSuccessDeleteOutletPhoto,
    isDeletingPhoto: isDeletingOutletPhoto,
    deletePhoto: deleteOutletPhoto,
  } = useOutlets(premise);
  const {
    updateTPOutletPhoto,
    isSuccessDeletePhoto: isSuccessDeleteTPOutletPhoto,
    isDeletingPhoto: isDeletingTPOutletPhoto,
    deletePhoto: deleteTPOutletPhoto,
  } = useTPOutlets(premise);

  const [isConfirmationWindowOpen, setIsConfirmationWindowOpen] = useState(false);
  const [urlToDelete, setUrlToDelete] = useState<string | null>(null);
  const [enlargedPhotoUrl, setEnlargedPhotoUrl] = useState<null | string>(null);

  const isDeletingPhoto = isDeletingOutletPhoto || isDeletingTPOutletPhoto;
  const isSuccessDeletePhoto = isSuccessDeleteOutletPhoto || isSuccessDeleteTPOutletPhoto;
  const {
    mutate,
    data: uploadedPhotoUrl,
    isLoading: isLoadingPhotoUpload,
    isError: isErrorPhotoUpload,
    isSuccess,
  } = useAzureOutletPhotoUpload();

  useEffect(() => {
    if (isSuccess && G.isNotNullable(uploadedPhotoUrl)) {
      toast.success(t('general.updatedSuccessfully'));
      if (outletType === AvailableOutletTypes.OUTLET) {
        updateOutletPhoto(outlet.id, uploadedPhotoUrl);
      } else if (outletType === AvailableOutletTypes.TPOUTLET) {
        updateTPOutletPhoto(outlet.id, uploadedPhotoUrl);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isErrorPhotoUpload) {
      toast.error(t('error.generalMessage'));
    }
  }, [isErrorPhotoUpload]);

  useEffect(() => {
    if (isSuccessDeletePhoto) {
      setEnlargedPhotoUrl(null);
      setIsConfirmationWindowOpen(false);

      toast.success(t('general.deletedSuccessfully'));
    }
  }, [isSuccessDeletePhoto]);

  const handleInitDelete = (e: React.MouseEvent<HTMLButtonElement>, photoUrl: string) => {
    e.stopPropagation();
    setUrlToDelete(photoUrl);
    setIsConfirmationWindowOpen(true);
  };
  const handleDelete = async () => {
    const url = enlargedPhotoUrl || urlToDelete;
    if (!url) return;
    if (outletType === AvailableOutletTypes.OUTLET) {
      await deleteOutletPhoto(url, outlet?.id ?? null);
    } else if (outletType === AvailableOutletTypes.TPOUTLET) {
      await deleteTPOutletPhoto(url, outlet?.id ?? null);
    }
  };

  return (
    <>
      <PhotoDialogs
        isConfirmationWindowOpen={isConfirmationWindowOpen}
        isDeletingPhoto={isDeletingPhoto}
        setIsConfirmationWindowOpen={setIsConfirmationWindowOpen}
        onDelete={handleDelete}
        enlargedPhotoUrl={enlargedPhotoUrl}
        onCloseEnlargedPhotoDialog={() => setEnlargedPhotoUrl(null)}
      />
      <List sx={{ display: 'flex', gap: 2, p: 0, flexWrap: 'wrap' }}>
        {outlet.imagesUrls.map((photoUrl, index) => (
          <ListItem
            key={`incoming-fiber-${photoUrl}}`}
            sx={{ p: 0, flex: '0 0', cursor: 'pointer' }}
            onClick={() => {
              setEnlargedPhotoUrl(photoUrl);
            }}
          >
            <Box width={96} height={96} sx={{ position: 'relative' }}>
              <Box component="img" src={photoUrl} alt={`outlet-image-${index}`} width="100%" height="100%" />
              <DeletePhotoButton handleInitDelete={(e) => handleInitDelete(e, photoUrl)} />
            </Box>
          </ListItem>
        ))}
        <ListItem sx={{ p: 0, flex: '0 0' }}>
          <ImagePicker
            isLoading={isLoadingPhotoUpload}
            onChange={(file) => {
              mutate(file);
            }}
          />
        </ListItem>
      </List>
    </>
  );
};
