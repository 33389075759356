import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Dialog, DialogContent, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { G } from '@mobily/ts-belt';
import * as styles from './styles';
import { publicUrl } from '../../../config';
import { useTranslation } from '../../hooks/useTranslation';

type PhotosDialogsProps = {
  enlargedPhotoUrl: null | string;
  isConfirmationWindowOpen: boolean;
  isDeletingPhoto: boolean;
  setIsConfirmationWindowOpen: Dispatch<SetStateAction<boolean>>;
  onDelete: VoidFunction;
  onCloseEnlargedPhotoDialog: VoidFunction;
};
export const PhotoDialogs = ({
  enlargedPhotoUrl,
  isConfirmationWindowOpen,
  isDeletingPhoto,
  setIsConfirmationWindowOpen,
  onDelete,
  onCloseEnlargedPhotoDialog,
}: PhotosDialogsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={isConfirmationWindowOpen} PaperProps={{ sx: styles.photoDeleteConfirmationDialog }}>
        {isDeletingPhoto}
        <Toolbar sx={styles.dialogTopbar}>
          <Typography>{t('premise.deletePhoto.confirmationText')}</Typography>
          <IconButton size="large" color="inherit" onClick={() => setIsConfirmationWindowOpen(false)}>
            <Box component="img" src={`${publicUrl}/img/icons/x.svg`} alt="add" />
          </IconButton>
        </Toolbar>

        <DialogContent sx={styles.dialogContent}>
          <Grid container sx={styles.deleteFibersButtonsContainer}>
            <Button
              variant="contained"
              onClick={() => setIsConfirmationWindowOpen(false)}
              data-testid="delete-photo-cancel"
            >
              {t('general.cancel')}
            </Button>
            <Button variant="outlined" onClick={onDelete} data-testid="delete-photo-accept" disabled={isDeletingPhoto}>
              {t('general.accept')}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={G.isNotNullable(enlargedPhotoUrl)} fullScreen>
        <Toolbar sx={styles.dialogTopbar}>
          <IconButton size="large" color="inherit" onClick={onCloseEnlargedPhotoDialog}>
            <Box component="img" src={`${publicUrl}/img/icons/x.svg`} alt="close" />
          </IconButton>
          <Button
            size="medium"
            variant="outlined"
            color="error"
            onClick={() => setIsConfirmationWindowOpen(true)}
            data-testid="delete-photo"
          >
            {t('premise.deletePhoto')}
          </Button>
        </Toolbar>

        {enlargedPhotoUrl && (
          <DialogContent sx={styles.dialogContent}>
            <Box sx={styles.enlargedPhoto} component="img" src={enlargedPhotoUrl} alt={enlargedPhotoUrl} />
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
