import { Styles } from '../../../app/theme/materialTheme';

export const deleteButton: Styles = (theme) => ({
  border: '1px solid',
  borderColor: 'white',
  backgroundColor: theme.palette.error.light,
  position: 'absolute',
  right: '-10px',
  top: '-10px',
  width: '28px',
  height: '28px',
});
