import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { G } from '@mobily/ts-belt';

import { requestGQL } from '../../../common/graphQlClient';
import { isTestEnv, oneHourInMs } from '../../../config';
import { PremiseOnSinglePremise } from '../../premises/Premise';
import { GET_OUTLETS, GetOutletsResponse, TPOutletPhotoUpload, UPDATE_TPOUTLETS_PHOTOS } from '../api';
import { parseGetTPOutletsResponse } from '../TPOutlet';
import { useDeleteTpOutletPhoto } from './useDeleteTpOutletPhotos';

export const useTPOutlets = (premise: null | PremiseOnSinglePremise) => {
  const queryClient = useQueryClient();

  const propertyId = useMemo(() => {
    return premise?.propertyId ?? null;
  }, [premise]);

  const {
    data,
    isLoading: isLoadingTPOutlets,
    isError: isErrorTPOutlets,
    isRefetching,
  } = useQuery<GetOutletsResponse>(
    ['outletsByPropertyId', propertyId],
    () => requestGQL(GET_OUTLETS, { id: propertyId }),
    {
      enabled: G.isNotNullable(premise),
      refetchOnWindowFocus: false,
      retry: isTestEnv ? 0 : 3,
      staleTime: oneHourInMs,
    },
  );

  const {
    deletePhoto,
    isSuccess: isSuccessDeletePhoto,
    isLoading: isDeletingPhoto,
  } = useDeleteTpOutletPhoto(propertyId);

  const connectionId = useMemo(() => {
    return data?.propertyById.connectionByConnectionId?.id ?? null;
  }, [data]);

  const { mutate } = useMutation<unknown, unknown, Partial<TPOutletPhotoUpload>>(
    ({ photoUrl, tpOutletId }) => requestGQL(UPDATE_TPOUTLETS_PHOTOS, { connectionId, photoUrl, tpOutletId }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
      },
    },
  );

  const tpOutlets = useMemo(() => {
    return G.isNullable(data) || G.isNullable(premise) ? [] : parseGetTPOutletsResponse(premise)(data);
  }, [data, isRefetching]);

  const updateTPOutletPhoto = (tpOutletId: string, photoUrl: string) => {
    mutate({
      tpOutletId,
      photoUrl,
    });
  };

  return {
    isLoadingTPOutlets,
    isErrorTPOutlets,
    tpOutlets,
    updateTPOutletPhoto,
    deletePhoto,
    isSuccessDeletePhoto,
    isDeletingPhoto,
  };
};
