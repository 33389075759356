import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import { useBoolean } from 'usehooks-ts';
import { G } from '@mobily/ts-belt';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from '../../../../common/hooks/useTranslation';
import { TwistedPair } from '../../../outlets/TPOutlet';
import { Outlet } from '../../../outlets/Outlet';
import { DeleteFiberDialog } from '../../../outlets/components/PremiseTPOutlets/components/DeleteFiberDialog';
import { DeleteTPOutletFiber } from '../../../outlets/components/PremiseTPOutlets/components/DeleteTpOutletFiber';
import { useAuthenticationState } from '../../../authentication/state';
import { NodeTypes } from '../../types/FiberList';

export const DeleteFiberButton = ({
  propertyId,
  outletId,
  twistedPair,
  nodeType,
}: {
  twistedPair: TwistedPair;
  outletId: Outlet['id'];
  propertyId: number;
  nodeType: NodeTypes;
}) => {
  const { t } = useTranslation();
  const { value: isDeleteFiberOpen, setValue: setIsDeleteFiberOpen } = useBoolean(false);

  const { userId, roles } = useAuthenticationState();
  const canUserDeleteFiber = useCallback(
    (fiber: TwistedPair) => {
      if (roles.includes('cf_admin') || roles.includes('cf_contractor_pm')) return true;

      const fiberCreatorId = fiber.personId;

      if (fiberCreatorId !== null && userId === fiberCreatorId) return true;

      return false;
    },
    [userId, roles],
  );

  if (!canUserDeleteFiber(twistedPair)) return null;

  return (
    <>
      <DeleteFiberDialog
        isOpen={G.isNotNullable(twistedPair) && isDeleteFiberOpen}
        onClose={() => {
          setIsDeleteFiberOpen(false);
        }}
      >
        {G.isNotNullable(twistedPair) && (
          <DeleteTPOutletFiber
            outletId={outletId}
            fiber={twistedPair}
            propertyId={propertyId!}
            nodeType={nodeType}
            onCancel={() => {
              setIsDeleteFiberOpen(false);
            }}
            onSuccess={() => {
              setIsDeleteFiberOpen(false);
            }}
          />
        )}
      </DeleteFiberDialog>
      <Button
        variant="text"
        color="error"
        onClick={() => {
          setIsDeleteFiberOpen(true);
        }}
      >
        <DeleteIcon /> {t('tp.delete.button')}
      </Button>
    </>
  );
};
