import { zodResolver } from '@hookform/resolvers/zod';
import { O } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDeleteBundle } from '../../hooks/useDelete';
import { usePatchBundle } from '../../hooks/usePatch';
import { incomingFiberSchema, IncomingFiberSchema } from '../../types/IncomingFiberSchema';
import { IncomingFiberDetails } from '../../types/ObjectDetails';
import { PhotosListWithUpload } from '../PhotosListWithUpload';

type Props = {
  details: IncomingFiberDetails;
  onSave: Lambda<void, void>;
  onDelete?: Lambda<void, void>;
};

export const IncomingFiber: FC<Props> = ({ details, onSave, onDelete }) => {
  const { t } = useTranslation();
  const { connectionsCount, location, room, netOwner, text, id, length } = details;
  const { mutate: patchBundle, isLoading: isLoadingPatchBundle } = usePatchBundle({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteBundle, isLoading: isLoadingDeleteBundle } = useDeleteBundle({
    onSuccess: () => {
      if (onDelete) onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IncomingFiberSchema>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      connectionsCount: O.getWithDefault(connectionsCount, 0),
      location: O.getWithDefault(location, ''),
      netOwner: O.getWithDefault(netOwner, ''),
      room: O.getWithDefault(room, ''),
      length: O.getWithDefault(length, 0),
    },
    mode: 'onChange',
    resolver: zodResolver(incomingFiberSchema),
  });

  const [photosURLs, setPhotosURLs] = useState(details.photosURLs ?? []);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: IncomingFiberSchema) => {
    patchBundle({ ...values, id, photosURLs });
  };

  const handleDelete = () => {
    deleteBundle(details.id);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(handleSave)}>
      <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.incomingFiber.label')}</Typography>
      <Stack gap={2} width="100%">
        <PhotosListWithUpload photosURLs={photosURLs} setPhotosUrls={setPhotosURLs} />
        <TextField label="Name" {...register('text')} />
        <TextField label="How to find object" {...register('location')} />
        <TextField label={t('areas.fields.room')} {...register('room')} />
        <TextField
          label={t('areas.fields.length')}
          type="number"
          inputProps={{ step: '0.1' }}
          {...register('length', { valueAsNumber: true })}
          helperText={getErrorMessage('length')}
        />
        <TextField
          label={t('areas.fields.connectionCount')}
          type="number"
          {...register('connectionsCount', {
            valueAsNumber: true,
          })}
          helperText={getErrorMessage('connectionsCount')}
        />
        <TextField label={t('areas.fields.netOwner')} {...register('netOwner')} />
      </Stack>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteBundle}>
          {isLoadingDeleteBundle ? <CircularProgress size={16} /> : t('general.delete')}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingPatchBundle}>
          {isLoadingPatchBundle ? <CircularProgress size={16} /> : t('general.save')}
        </Button>
      </Grid>
    </Stack>
  );
};
