import { zodResolver } from '@hookform/resolvers/zod';
import { O } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { LinkNodeToTPOutlet } from 'app/fibers/components/LinkNodeToTPOutlet';
import { useDeleteWifiDot } from '../../hooks/useDelete';
import { usePatchWifiDot } from '../../hooks/usePatch';
import { WifiDotDetails } from '../../types/ObjectDetails';
import { wifiDotSchema, WifiDotSchema } from '../../types/WifiDotSchema';
import * as styles from '../../../outlets/components/PremiseTPOutlets/components/styles';
import { FiberList } from './FiberList';
import { nodeTypes } from '../../types/FiberList';
import { PhotosListWithUpload } from '../PhotosListWithUpload';

type Props = {
  details: WifiDotDetails;
  onSave: Lambda<void, void>;
  onDelete?: Lambda<void, void>;
};
export const WifiDot: FC<Props> = ({ details, onSave, onDelete }) => {
  const { t } = useTranslation();
  const { portCount, text, location, id, ip, mac, serialNumber } = details;
  const { mutate: patchWifiDot, isLoading: isLoadingPatchWifiDot } = usePatchWifiDot({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteWifiDot, isLoading: isLoadingDeleteWifiDot } = useDeleteWifiDot({
    onSuccess: () => {
      if (onDelete) onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<WifiDotSchema>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      portCount: O.getWithDefault(portCount, 0),
      location: O.getWithDefault(location, ''),
      ip: O.getWithDefault(ip, ''),
      mac: O.getWithDefault(mac, ''),
      serialNumber: O.getWithDefault(serialNumber, ''),
    },
    mode: 'onChange',
    resolver: zodResolver(wifiDotSchema),
  });

  const [photosURLs, setPhotosURLs] = useState(details.photosURLs ?? []);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: WifiDotSchema) => {
    patchWifiDot({ ...values, id, photosURLs });
  };

  const handleDelete = () => {
    deleteWifiDot(details.id);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(handleSave)}>
      <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.wifiDot.label')}</Typography>
      <Stack gap={2} width="100%">
        <PhotosListWithUpload photosURLs={photosURLs} setPhotosUrls={setPhotosURLs} />

        <TextField label={t('areas.fields.name')} {...register('text')} />
        <TextField label={t('areas.fields.location')} {...register('location')} />
        <TextField
          label={t('areas.fields.connectionCount')}
          type="number"
          {...register('portCount', {
            valueAsNumber: true,
          })}
          helperText={getErrorMessage('portCount')}
        />
        <TextField label={t('areas.fields.ip')} {...register('ip')} />
        <TextField label={t('areas.fields.mac')} {...register('mac')} />
        <TextField label={t('areas.fields.serialNumber')} {...register('serialNumber')} />
      </Stack>
      <Grid container justifyContent="space-between" mt={2}>
        <Grid container sx={styles.centerContainer}>
          <LinkNodeToTPOutlet nodeDetails={details} />
          <Grid container sx={styles.fibersContainer}>
            <FiberList outletId={details.id} nodeType={nodeTypes.WIFI_DOT} />
          </Grid>
        </Grid>
        <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteWifiDot}>
          {isLoadingDeleteWifiDot ? <CircularProgress size={16} /> : t('general.delete')}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingPatchWifiDot}>
          {isLoadingPatchWifiDot ? <CircularProgress size={16} /> : t('general.save')}
        </Button>
      </Grid>
    </Stack>
  );
};
