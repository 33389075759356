import { zodResolver } from '@hookform/resolvers/zod';
import { O } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDeleteTpOutlet } from '../../hooks/useDelete';
import { usePatchTpOutlet } from '../../hooks/usePatch';
import { TpOutletDetails } from '../../types/ObjectDetails';
import { tpOutletSchema, TpOutletSchema } from '../../types/TpOutletSchema';
import * as styles from '../../../outlets/components/PremiseTPOutlets/components/styles';
import { AddTPOutletTwistedPair } from '../../../fibers/components/AddTPOutletTwistedPair';
import { AddFiberTPOutletSchema } from '../../../fibers/addFiberTPOutletSchema';
import { FiberList } from './FiberList';
import { nodeTypes } from '../../types/FiberList';
import { PhotosListWithUpload } from '../PhotosListWithUpload';

type Props = {
  details: TpOutletDetails;
  onSave: Lambda<void, void>;
  onDelete?: Lambda<void, void>;
};

export const TpOutlet: FC<Props> = ({ details, onSave, onDelete }) => {
  const { t } = useTranslation();
  const { portCount, text, location, id } = details;
  const { mutate: patchTPOutlet, isLoading: isLoadingPatchTPOutlet } = usePatchTpOutlet({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteWifiDot, isLoading: isLoadingDeleteWifiDot } = useDeleteTpOutlet({
    onSuccess: () => {
      if (onDelete) onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<TpOutletSchema>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      portCount: O.getWithDefault(portCount, 0),
      location: O.getWithDefault(location, ''),
    },
    mode: 'onChange',
    resolver: zodResolver(tpOutletSchema),
  });
  const { control } = useForm<AddFiberTPOutletSchema>();

  const { isValid: isTwisterPairFormValid } = useFormState({ control });

  const [photosURLs, setPhotosURLs] = useState(details.photosURLs ?? []);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: TpOutletSchema) => {
    patchTPOutlet({ ...values, id, photosURLs });
  };

  const handleDelete = () => {
    deleteWifiDot(details.id);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(handleSave)}>
      <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.tpOutlet.label')}</Typography>
      <Stack gap={2} width="100%">
        <PhotosListWithUpload photosURLs={photosURLs} setPhotosUrls={setPhotosURLs} />
        <TextField label={t('areas.fields.name')} {...register('text')} />
        <TextField label={t('areas.fields.location')} {...register('location')} />
        <TextField
          label={t('areas.fields.connectionCount')}
          type="number"
          {...register('portCount', {
            valueAsNumber: true,
          })}
          helperText={getErrorMessage('portCount')}
        />
      </Stack>
      <Grid container justifyContent="space-between" mt={2}>
        <Grid container sx={styles.centerContainer}>
          <AddTPOutletTwistedPair outletDetails={details} />
          <Grid container sx={styles.fibersContainer}>
            <FiberList outletId={details.id} nodeType={nodeTypes.TP_Outlet} />
          </Grid>
        </Grid>
        <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteWifiDot}>
          {isLoadingDeleteWifiDot ? <CircularProgress size={16} /> : t('general.delete')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || isLoadingPatchTPOutlet || !isTwisterPairFormValid}
        >
          {isLoadingPatchTPOutlet ? <CircularProgress size={16} /> : t('general.save')}
        </Button>
      </Grid>
    </Stack>
  );
};
