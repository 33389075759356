import { useState } from 'react';
import { toast } from 'react-toastify';
import { azureDelete, getAzureSignedLinkObjectPhotoDelete } from '../../outlets/api';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const useAzureObjectPhotoDelete = () => {
  const [isDeletingPhoto, setIsDeletingPhoto] = useState(false);
  const { t } = useTranslation();

  const deletePhoto = async (photoUrl: string) => {
    setIsDeletingPhoto(true);
    try {
      const { url, query, container } = await getAzureSignedLinkObjectPhotoDelete(photoUrl);

      const photoName = photoUrl.substring(photoUrl.lastIndexOf('/') + 1);

      await azureDelete(photoName, `${url}?${query}`, container);
    } catch (_err) {
      toast.error(t('error.generalMessage'));
    } finally {
      setIsDeletingPhoto(false);
    }
  };

  return { deletePhoto, isDeletingPhoto };
};
