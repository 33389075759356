import { O, pipe } from '@mobily/ts-belt';
import { Alert, Box, Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateBaseStation } from 'app/areas/hooks/useCreate';
import { BaseStationSchema, baseStationSchema } from 'app/areas/types/BaseStationSchema';
import { PhotosField } from 'app/areas/components/PhotosField/PhotosField';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { BaseStationDetails } from 'app/areas/types/ObjectDetails';
import { EnlargedPhotoDialog } from '../EnlargedPhotoDialog';

import { OptionalSelect } from '../../ui/OptionalSelect';
import { useGetPropertyRadioConnections } from '../../hooks/useGetPropertyRadioConnections';
import { getRadioObject } from '../../getRadioObject';
import { getAreasRadio } from '../../getAreas';
import { BaseStation } from '../ObjectDetails/BaseStation';

export const CreateBaseStation: FC<{ onSave: Lambda<void, void> }> = ({ onSave }) => {
  const { status, data } = useGetPropertyRadioConnections();
  const [objectId, setObjectId] = useState<O.Option<string>>(O.None);
  const { t } = useTranslation();

  const [pickedImage, setPickedImage] = useState<O.Option<string>>(O.None);
  const { mutate: createBaseStation, isLoading: isLoadingCreateBaseStation } = useCreateBaseStation({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<BaseStationSchema>({
    defaultValues: {
      text: '',
      location: '',
      portCount: 1,
      unitType: '',
      operator: '',
      voltage: 0,
      other: '',
    },
    mode: 'onChange',
    resolver: zodResolver(baseStationSchema),
  });

  const [photosURLs, setPhotosURLs] = useState<BaseStationDetails['photosURLs']>([]);

  const getErrorMessage = useErrorMessage(errors);

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    return <Alert severity="error">{t('areas.properties.error')}</Alert>;
  }

  const { baseStations } = getAreasRadio(data);

  const selectedObjectDetails = pipe(
    objectId,
    O.flatMap((id) => getRadioObject(id)(data)),
  );

  const handleSave = (values: BaseStationSchema) => {
    createBaseStation({ ...values, photosURLs });
  };

  if (objectId) return null;

  return (
    <Stack>
      <Box my={2}>
        <EnlargedPhotoDialog
          photoUrl={pickedImage}
          onClose={() => {
            setPickedImage(O.None);
          }}
        />
        <Stack component="form" onSubmit={handleSubmit(handleSave)}>
          <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.baseStation.label')}</Typography>
          <Stack gap={2} width="100%">
            <TextField label={t('areas.fields.name')} {...register('text')} helperText={getErrorMessage('text')} />
            <TextField
              label={t('areas.fields.portCount')}
              type="number"
              {...register('portCount', {
                valueAsNumber: true,
              })}
              helperText={getErrorMessage('portCount')}
            />
            <PhotosField
              value={photosURLs}
              onChange={setPhotosURLs}
              onItemClick={(photoUrl) => {
                setPickedImage(O.Some(photoUrl));
              }}
            />
            <TextField label={t('areas.fields.location')} {...register('location')} />
            <TextField label={t('areas.fields.unitType')} {...register('unitType')} />
            <TextField label={t('areas.fields.operator')} {...register('operator')} />
            <TextField
              label={t('areas.fields.voltage')}
              type="number"
              {...register('voltage', {
                valueAsNumber: true,
              })}
            />
            <TextField label={t('areas.fields.other')} {...register('other')} />
          </Stack>
          <Grid container justifyContent="space-between" mt={2}>
            <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingCreateBaseStation}>
              {isLoadingCreateBaseStation ? <CircularProgress size={16} /> : t('general.create')}
            </Button>
          </Grid>
        </Stack>
      </Box>
      <Box mt={2}>
        <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.object.edit')}</Typography>
      </Box>

      {baseStations.length === 0 ? (
        <Alert severity="error" sx={{ width: 'unset' }}>
          {t('areas.object.noAvailable')}
        </Alert>
      ) : (
        <>
          <OptionalSelect
            options={baseStations.map(({ id, text }) => ({ id, label: O.getWithDefault(text, id) }))}
            value={objectId}
            onChange={setObjectId}
          />
          <Box mt={2}>
            {O.match(
              selectedObjectDetails,
              (details) => {
                if (details.type !== 'BaseStation') {
                  return null;
                }
                return <BaseStation key={details.id} details={details} onSave={onSave} />;
              },
              () => (
                <Alert severity="info" sx={{ width: 'unset' }}>
                  {t('areas.object.noSelected')}
                </Alert>
              ),
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};
