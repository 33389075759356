import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { usePropertyContext } from '../../../properties/components/SingleProperty';
import { useConnection } from '../../../connection/hooks/useConnection';
import { getTPOutletFibers } from '../../../connection/Connection';
import { mapLinkDataToTwistedPair } from '../../../outlets/TPOutlet';
import { FiberTPOutletDetails } from '../../../outlets/components/PremiseTPOutlets/components/FiberTPOutletDetails';
import * as styles from '../../../outlets/components/PremiseTPOutlets/components/styles';
import { EditFiberButton } from './EditFiberButton';
import { DeleteFiberButton } from './DeleteFiberButton';
import { FiberListProps } from '../../types/FiberList';

export const FiberList = ({ outletId, nodeType }: FiberListProps) => {
  const { id: propertyId } = usePropertyContext();
  const { serialized2 } = useConnection(propertyId);
  const fibers = getTPOutletFibers(serialized2, outletId);

  if (!Array.isArray(fibers)) return null;
  return (
    <>
      {fibers.map((fiberLink) => {
        const twistedPair = mapLinkDataToTwistedPair(fiberLink, serialized2!);
        return (
          <Accordion disableGutters square key={twistedPair.id} data-cy={`fiber-accordion-${twistedPair.to.portId}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon titleAccess="accordion-expand" />}
              data-cy="fiber-accordion-header"
            >
              {twistedPair.to.portId}
            </AccordionSummary>
            <AccordionDetails data-cy="fiber-accordion-details">
              {serialized2 && propertyId && <FiberTPOutletDetails fiber={twistedPair} />}
              {twistedPair && (
                <Grid sx={styles.actionFiberContainer}>
                  <EditFiberButton outletId={outletId} propertyId={propertyId!} twistedPair={twistedPair} />
                  <DeleteFiberButton
                    twistedPair={twistedPair}
                    outletId={outletId}
                    propertyId={propertyId!}
                    nodeType={nodeType}
                  />
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
