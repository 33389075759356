import { zodResolver } from '@hookform/resolvers/zod';
import { O } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDeleteCoupler } from '../../hooks/useDelete';
import { usePatchCoupler } from '../../hooks/usePatch';
import { CouplerDetails } from '../../types/ObjectDetails';
import { couplerSchema, CouplerSchema } from '../../types/CouplerSchema';
import { PhotosListWithUpload } from '../PhotosListWithUpload';

type Props = {
  details: CouplerDetails;
  onSave: Lambda<void, void>;
  onDelete?: Lambda<void, void>;
};
export const Coupler: FC<Props> = ({ details, onSave, onDelete }) => {
  const { t } = useTranslation();
  const { location, text, id, photosURLs, portCount, unitType } = details;
  const { mutate: patchCoupler, isLoading: isLoadingPatchCoupler } = usePatchCoupler({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteCoupler, isLoading: isLoadingDeleteCoupler } = useDeleteCoupler({
    onSuccess: () => {
      if (onDelete) onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<any>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      location: O.getWithDefault(location, ''),
      portCount: O.getWithDefault(portCount, 4),
      unitType: O.getWithDefault(unitType, ''),
    },
    mode: 'onChange',
    resolver: zodResolver(couplerSchema),
  });

  const [photosUrls, setPhotosUrls] = useState(photosURLs ?? []);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: CouplerSchema) => {
    patchCoupler({ ...values, id, photosURLs: photosUrls });
  };

  const handleDelete = () => {
    deleteCoupler(details.id);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(handleSave)}>
      <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.coupler.label')}</Typography>
      <Stack gap={2} width="100%">
        <TextField label={t('areas.fields.name')} {...register('text')} />
        <TextField
          label={t('areas.fields.portCount')}
          {...register('portCount', {
            valueAsNumber: true,
          })}
          type="number"
          helperText={getErrorMessage('portCount')}
        />
        <PhotosListWithUpload photosURLs={photosUrls} setPhotosUrls={setPhotosUrls} />
        <TextField
          label={t('areas.fields.location')}
          {...register('location')}
          helperText={getErrorMessage('location')}
        />
        <TextField
          label={t('areas.fields.unitType')}
          {...register('unitType')}
          helperText={getErrorMessage('unitType')}
        />
      </Stack>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteCoupler}>
          {isLoadingDeleteCoupler ? <CircularProgress size={16} /> : t('general.delete')}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingPatchCoupler}>
          {isLoadingPatchCoupler ? <CircularProgress size={16} /> : t('general.save')}
        </Button>
      </Grid>
    </Stack>
  );
};
