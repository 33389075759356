import React from 'react';
import { Box, IconButton } from '@mui/material';
import { publicUrl } from '../../../config';
import { deleteButton } from './styles';

type DeletePhotoButtonProps = {
  handleInitDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const DeletePhotoButton = ({ handleInitDelete }: DeletePhotoButtonProps) => (
  <IconButton size="small" color="inherit" onClick={(e) => handleInitDelete(e)} sx={deleteButton}>
    <Box component="img" width={16} height={16} src={`${publicUrl}/img/icons/xWhite.svg`} alt="close" />
  </IconButton>
);
