import { authenticationState } from 'app/authentication/state';
import { SerializedData as ConnectionSerializedData, NodeData } from 'app/connection/api';
import axios from 'axios';
import { apiUri } from 'config';
import { gql } from 'graphql-request';

import { BlobServiceClient } from '@azure/storage-blob';

export type PropertyConnection = {
  id: number;
  serialized2: ConnectionSerializedData;
};

export type OutletPhotoUpload = {
  connectionId: number | undefined;
  photoUrl: string;
  outletId: string;
};

export type TPOutletPhotoUpload = {
  connectionId: number | undefined;
  photoUrl: string;
  tpOutletId: string;
};

export type GetOutletsResponse = {
  propertyById: {
    connectionByConnectionId: PropertyConnection;
  };
};

export type GetRadioOutletsResponse = {
  propertyById: {
    radioConnectionByRadioConnectionId: PropertyConnection;
  };
};

export const CREATE_OUTLET = gql`
  mutation createOutlet($propertyId: Int!, $premiseId: Int!) {
    createOutlet(input: { propertyId: $propertyId, premiseId: $premiseId })
  }
`;

export const DELETE_OUTLET = gql`
  mutation deleteOutlet($propertyId: Int!, $outletId: String!) {
    deleteOutlet(input: { propertyId: $propertyId, outletId: $outletId })
  }
`;

export const CREATE_TPOUTLET = gql`
  mutation createTpOutlet($propertyId: Int!, $premiseId: Int!) {
    createTpOutlet(input: { propertyId: $propertyId, premiseId: $premiseId })
  }
`;

export const CREATE_PREMISE_DROP_ANTENNA = gql`
  mutation createDropAntenna($propertyId: Int!, $premiseId: Int!, $dropAntennaDetails: DropAntennaDetails!) {
    createDropAntenna(
      input: { propertyId: $propertyId, premiseId: $premiseId, dropAntennaDetails: $dropAntennaDetails }
    )
  }
`;

export const DELETE_TPOUTLET = gql`
  mutation deleteTpOutlet($propertyId: Int!, $tpOutletId: String!) {
    deleteTpOutlet(input: { propertyId: $propertyId, nodeId: $tpOutletId })
  }
`;
export const GET_OUTLETS = gql`
  query propertyConnection($id: Int!) {
    propertyById(id: $id) {
      connectionByConnectionId {
        id
        serialized2
      }
    }
  }
`;

export const UPDATE_OUTLET_LOCATION = gql`
  mutation updateOutletLocation($propertyId: Int!, $outletId: String!, $location: String!, $contactType: String) {
    updateOutletLocation(
      input: { propertyId: $propertyId, outletId: $outletId, location: $location, contactType: $contactType }
    )
  }
`;

export type GetAzureSignedLinkResponse = {
  container: 'outlet' | 'objects';
  query: string;
  url: string;
};

export const getAzureSignedLinkOutlet = () => {
  const { token } = authenticationState();

  return axios
    .post<GetAzureSignedLinkResponse>(`${apiUri}/api/v1/azurestorage/upload/outlet`, null, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data);
};

type OutletPhotoDeleteArgs = {
  photoUrl: string;
  outletId: string | null;
  connectionId: number | undefined;
};

export const getAzureSignedLinkOutletPhotoDelete = ({ outletId, connectionId, photoUrl }: OutletPhotoDeleteArgs) => {
  const { token } = authenticationState();

  return axios
    .post<GetAzureSignedLinkResponse>(`${apiUri}/api/v1/azurestorage/delete/outlet`, null, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      params: {
        connectionId,
        outletId,
        photoUrl,
      },
    })
    .then((response) => response.data);
};

export const getAzureSignedLinkObjectPhotoDelete = (fileUrl: string) => {
  const { token } = authenticationState();

  return axios
    .post<GetAzureSignedLinkResponse>(
      `${apiUri}/api/v1/azurestorage/delete/objects`,
      {
        file: fileUrl,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => response.data);
};

export const azureUpload = (file: File, url: string) => {
  return new BlobServiceClient(url).getContainerClient('outlet').uploadBlockBlob(file.name, file, file.size);
};

export const azureDelete = (fileName: string, url: string, container: GetAzureSignedLinkResponse['container']) => {
  return new BlobServiceClient(url).getContainerClient(container).getBlockBlobClient(fileName).delete();
};

export type MutateCreateFiberFiber = {
  id: null;
  odfId: string;
  odfPort: string;
  outletId: string;
  duct: string;
  length: number;
  db1310: number;
  db1550: number;
  contract: null;
};

export type MutateCreateTwistedPair = {
  id: null;
  length: number;
  category: string;
};

export const CREATE_FIBER = gql`
  mutation createFiber($propertyId: Int!, $fiber: FiberInput!) {
    createFiber(input: { propertyId: $propertyId, fiber: $fiber })
  }
`;

export const CREATE_TWISTER_PAIR = gql`
  mutation createTwistedPair($propertyId: Int!, $twistedPair: TwistedPairInput!) {
    createTwistedPair(input: { propertyId: $propertyId, twistedPair: $twistedPair })
  }
`;

export const CREATE_COAX = gql`
  mutation createCoaxial($propertyId: Int!, $coaxial: CoaxialInput!) {
    createCoaxial(input: { propertyId: $propertyId, coaxial: $coaxial })
  }
`;

export type MutatePatchFiber = {
  id: string;
  odfId: string;
  odfPort: string;
  outletId: string;
  outletPort: string;
  duct?: string;
  length?: number;
  db1310?: number;
  db1550?: number;
  contract?: string;
};

export const PATCH_FIBER = gql`
  mutation patchFiber($propertyId: Int!, $fiber: FiberInput!) {
    patchFiber(input: { propertyId: $propertyId, fiber: $fiber })
  }
`;

export type LinkSideInput = {
  portId: string;
  nodeId: string;
};

export type MutatePatchTwistedPair = {
  id: string;
  category: string;
  shielding: string;
  length: number;
  from: LinkSideInput;
  to: LinkSideInput;
};

export type MutatePatchCoax = {
  id: string;
  class: string;
  length: number;
  from: LinkSideInput;
  to: LinkSideInput;
};

export const DELETE_FIBER = gql`
  mutation deleteFiber($propertyId: Int!, $fiberId: String!, $outletId: String!) {
    deleteFiber(input: { propertyId: $propertyId, fiberId: $fiberId, outletId: $outletId })
  }
`;

export const PATCH_TWISTED_PAIR = gql`
  mutation patchTwistedPair($propertyId: Int!, $twistedPair: TwistedPairInput!) {
    patchTwistedPair(input: { propertyId: $propertyId, twistedPair: $twistedPair })
  }
`;

export const DELETE_TWISTED_PAIR = gql`
  mutation deleteTwistedPair($propertyId: Int!, $twistedPairId: String!) {
    deleteTwistedPair(input: { propertyId: $propertyId, twistedPairId: $twistedPairId })
  }
`;

export const PATCH_COAX = gql`
  mutation patchCoaxial($propertyId: Int!, $coaxial: CoaxialInput!) {
    patchCoaxial(input: { propertyId: $propertyId, coaxial: $coaxial })
  }
`;

export const DELETE_COAX = gql`
  mutation deleteCoaxial($propertyId: Int!, $coaxialId: String!) {
    deleteCoaxial(input: { propertyId: $propertyId, coaxialId: $coaxialId })
  }
`;

export const UPDATE_OUTLETS_PHOTOS = gql`
  mutation uploadOutletPhoto($connectionId: Int!, $photoUrl: String!, $outletId: String!) {
    uploadOutletPhoto(input: { connectionId: $connectionId, photoUrl: $photoUrl, outletId: $outletId })
  }
`;

export const DELETE_OUTLET_PHOTO = gql`
  mutation deleteOutletPhoto($connectionId: Int!, $photoUrl: String!, $outletId: String!) {
    deleteOutletPhoto(input: { connectionId: $connectionId, photoUrl: $photoUrl, outletId: $outletId })
  }
`;

export const UPDATE_TPOUTLETS_PHOTOS = gql`
  mutation uploadTpOutletPhoto($connectionId: Int!, $photoUrl: String!, $tpOutletId: String!) {
    uploadTpOutletPhoto(input: { connectionId: $connectionId, photoUrl: $photoUrl, tpOutletId: $tpOutletId })
  }
`;

export const DELETE_TPOUTLET_PHOTO = gql`
  mutation deleteTpOutletPhoto($connectionId: Int!, $photoUrl: String!, $tpOutletId: String!) {
    deleteTpOutletPhoto(input: { connectionId: $connectionId, photoUrl: $photoUrl, tpOutletId: $tpOutletId })
  }
`;

export type CoaxClass = 'thick' | 'thin';

export type Coax = {
  id: string;
  class: CoaxClass;
  length: number;
  personId: string | null;
  from: LinkSideInput;
  to: LinkSideInput;
  sourceNode?: NodeData | null;
};

export const GET_RADIO_OUTLETS = gql`
  query propertyConnection($id: Int!) {
    propertyById(id: $id) {
      radioConnectionByRadioConnectionId {
        id
        serialized2
      }
    }
  }
`;
