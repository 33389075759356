import { zodResolver } from '@hookform/resolvers/zod';
import { O } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ODFContactTypes } from 'app/areas/ODFContactTypes';
import { SwitchTypes } from 'app/areas/SwitchTypes';
import { TechnicalSwitchDeviceTypes } from 'app/areas/TechnicalSwitchDeviceTypes';
import { useDeleteTechnicalSwitch } from '../../hooks/useDelete';
import { usePatchTechnicalSwitch } from '../../hooks/usePatch';
import { TechnicalSwitchDetails } from '../../types/ObjectDetails';
import { technicalSwitchSchema, TechnicalSwitchSchema } from '../../types/TechnicalSwitchSchema';
import { PhotosListWithUpload } from '../PhotosListWithUpload';

type Props = {
  details: TechnicalSwitchDetails;
  onSave: Lambda<void, void>;
  onDelete?: Lambda<void, void>;
};

export const TechnicalSwitch: FC<Props> = ({ details, onDelete, onSave }) => {
  const { t } = useTranslation();
  const {
    connectionsCount,
    location,
    model,
    brand,
    installedAt,
    propertyOf,
    switchType,
    contactType,
    deviceType,
    text,
    id,
  } = details;
  const { mutate: patchTechnicalSwitch, isLoading: isLoadingPatchSwitch } = usePatchTechnicalSwitch({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteTechnicalSwitch, isLoading: isLoadingDeleteSwitch } = useDeleteTechnicalSwitch({
    onSuccess: () => {
      if (onDelete) onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<TechnicalSwitchSchema>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      connectionsCount: O.getWithDefault(connectionsCount, 0),
      location: O.getWithDefault(location, ''),
      model: O.getWithDefault(model, ''),
      brand: O.getWithDefault(brand, ''),
      propertyOf: O.getWithDefault(propertyOf, ''),
      installedAt: O.getWithDefault(installedAt, new Date().toISOString()),
      switchType: O.getWithDefault(switchType, ''),
      contactType: O.getWithDefault(contactType, ''),
      deviceType: O.getWithDefault(deviceType, ''),
    },
    mode: 'onChange',
    resolver: zodResolver(technicalSwitchSchema),
  });

  const [photosURLs, setPhotosURLs] = useState(details.photosURLs ?? []);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: TechnicalSwitchSchema) => {
    patchTechnicalSwitch({ ...values, id, photosURLs });
  };

  const handleDelete = () => {
    deleteTechnicalSwitch(details.id);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(handleSave)}>
      <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.technicalSwitch.label')}</Typography>
      <Stack gap={2} width="100%">
        <PhotosListWithUpload photosURLs={photosURLs} setPhotosUrls={setPhotosURLs} />

        <TextField label={t('areas.fields.name')} {...register('text')} />
        <TextField
          {...register('deviceType', { required: true })}
          select
          fullWidth
          label={t('areas.technicalSwitch.deviceType')}
          variant="outlined"
          helperText={getErrorMessage('deviceType')}
          value={watch('deviceType') || ''}
        >
          {Object.values(TechnicalSwitchDeviceTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {t('areas.fields.technical')} {type}
            </MenuItem>
          ))}
        </TextField>
        <TextField label={t('areas.fields.location')} {...register('location')} />
        <TextField
          label={t('areas.fields.connectionCount')}
          type="number"
          {...register('connectionsCount', {
            valueAsNumber: true,
          })}
          helperText={getErrorMessage('connectionsCount')}
        />
        <TextField
          {...register('switchType', { required: true })}
          select
          fullWidth
          label={t('areas.switch.switchType')}
          variant="outlined"
          helperText={getErrorMessage('switchType')}
          value={watch('switchType') || ''}
        >
          {Object.values(SwitchTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
        <TextField label={t('areas.fields.model')} {...register('model')} />
        <TextField label={t('areas.fields.brand')} {...register('brand')} />
        <TextField label={t('areas.fields.propertyOf')} {...register('propertyOf')} />
        <TextField label={t('areas.fields.installedAt')} {...register('installedAt')} disabled />
        <TextField
          {...register('contactType', { required: true })}
          select
          fullWidth
          label={t('areas.odf.contactType')}
          variant="outlined"
          helperText={getErrorMessage('contactType')}
          value={watch('contactType') || ''}
        >
          {Object.values(ODFContactTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteSwitch}>
          {isLoadingDeleteSwitch ? <CircularProgress size={16} /> : t('general.delete')}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingPatchSwitch}>
          {isLoadingPatchSwitch ? <CircularProgress size={16} /> : t('general.save')}
        </Button>
      </Grid>
    </Stack>
  );
};
