import { zodResolver } from '@hookform/resolvers/zod';
import { O } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ODFContactTypes } from 'app/areas/ODFContactTypes';
import { useDeleteTechnicalODF } from '../../hooks/useDelete';
import { usePatchTechnicalODF } from '../../hooks/usePatch';
import { TechnicalODFDetails } from '../../types/ObjectDetails';
import { odfSchema, ODFSchema } from '../../types/ODFSchema';
import { PhotosListWithUpload } from '../PhotosListWithUpload';

type Props = {
  details: TechnicalODFDetails;
  onSave: Lambda<void, void>;
  onDelete?: Lambda<void, void>;
};

export const TechnicalODF: FC<Props> = ({ details, onSave, onDelete }) => {
  const { t } = useTranslation();
  const { connectionsCount, location, text, id, contactType } = details;
  const { mutate: patchTechnicalODF, isLoading: isLoadingPatchTechnicalODF } = usePatchTechnicalODF({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteTechnicalODF, isLoading: isLoadingDeleteTechnicalODF } = useDeleteTechnicalODF({
    onSuccess: () => {
      if (onDelete) onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<ODFSchema>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      connectionsCount: O.getWithDefault(connectionsCount, 0),
      location: O.getWithDefault(location, ''),
      contactType: O.getWithDefault(contactType, ''),
    },
    mode: 'onChange',
    resolver: zodResolver(odfSchema),
  });

  const [photosURLs, setPhotosURLs] = useState(details.photosURLs ?? []);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: ODFSchema) => {
    patchTechnicalODF({ ...values, id, photosURLs });
  };

  const handleDelete = () => {
    deleteTechnicalODF(details.id);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(handleSave)}>
      <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.technicalOdf.label')}</Typography>
      <Stack gap={2} width="100%">
        <PhotosListWithUpload photosURLs={photosURLs} setPhotosUrls={setPhotosURLs} />

        <TextField label={t('areas.fields.name')} {...register('text')} />
        <TextField label={t('areas.fields.location')} {...register('location')} />
        <TextField
          label={t('areas.fields.connectionCount')}
          type="number"
          {...register('connectionsCount', {
            valueAsNumber: true,
          })}
          helperText={getErrorMessage('connectionsCount')}
        />
        <TextField
          {...register('contactType', { required: true })}
          select
          fullWidth
          label={t('areas.odf.contactType')}
          variant="outlined"
          helperText={getErrorMessage('contactType')}
          value={watch('contactType') || ''}
        >
          {Object.values(ODFContactTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteTechnicalODF}>
          {isLoadingDeleteTechnicalODF ? <CircularProgress size={16} /> : t('general.delete')}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingPatchTechnicalODF}>
          {isLoadingPatchTechnicalODF ? <CircularProgress size={16} /> : t('general.save')}
        </Button>
      </Grid>
    </Stack>
  );
};
