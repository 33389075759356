import { G } from '@mobily/ts-belt';
import { useConnection } from '../../connection/hooks/useConnection';
import { useMutateDeleteTpOutletPhoto } from './useMutateDeleteTpOutletPhoto';
import { azureDelete, getAzureSignedLinkOutletPhotoDelete } from '../api';

export const useDeleteTpOutletPhoto = (propertyId: null | number) => {
  const {
    serialized2,
    id,
    isLoading: isLoadingUseConnection,
    isError: isErrorUseConnection,
  } = useConnection(propertyId);

  const {
    mutate,
    isLoading: isLoadingMutateDeleteOutletPhoto,
    isError: isErrorMutateDeleteOutletPhoto,
    isSuccess,
  } = useMutateDeleteTpOutletPhoto(propertyId);

  const handleDeletePhoto = async (photoUrl: string, tpOutletId: null | string) => {
    if (!tpOutletId || !id) return;
    const { url, query, container } = await getAzureSignedLinkOutletPhotoDelete({
      outletId: tpOutletId,
      connectionId: id,
      photoUrl,
    });

    const photoName = photoUrl.substring(photoUrl.lastIndexOf('/') + 1);

    await azureDelete(photoName, `${url}?${query}`, container);

    if (G.isNotNullable(serialized2) && G.isNotNullable(tpOutletId)) {
      mutate({ tpOutletId, connectionId: id, photoUrl });
    }
  };

  return {
    deletePhoto: handleDeletePhoto,
    isLoading: isLoadingUseConnection || isLoadingMutateDeleteOutletPhoto,
    isError: isErrorUseConnection || isErrorMutateDeleteOutletPhoto,
    isSuccess,
  };
};
