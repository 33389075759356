import { zodResolver } from '@hookform/resolvers/zod';
import { O } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDeleteCableRoute } from '../../hooks/useDelete';
import { usePatchCableRoute } from '../../hooks/usePatch';
import { cableRouteSchema, CableRouteSchema } from '../../types/CableRouteSchema';
import { CableRouteDetails } from '../../types/ObjectDetails';
import { PhotosListWithUpload } from '../PhotosListWithUpload';

type Props = {
  details: CableRouteDetails;
  onSave: Lambda<void, void>;
  onDelete: Lambda<void, void>;
};

export const CableRoute: FC<Props> = ({ details, onSave, onDelete }) => {
  const { t } = useTranslation();
  const { location, text, id } = details;
  const { mutate: patchCableRoute, isLoading: isLoadingCableRoute } = usePatchCableRoute({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteCableRoute, isLoading: isLoadingDeleteCableRoute } = useDeleteCableRoute({
    onSuccess: () => {
      onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<CableRouteSchema>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      location: O.getWithDefault(location, ''),
    },
    mode: 'onChange',
    resolver: zodResolver(cableRouteSchema),
  });

  const [photosURLs, setPhotosURLs] = useState(details.photosURLs ?? []);

  const handleSave = (values: CableRouteSchema) => {
    patchCableRoute({ ...values, id, photosURLs });
  };

  const handleDelete = () => {
    deleteCableRoute(details.id);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(handleSave)}>
      <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.cableRoute.label')}</Typography>
      <Stack gap={2} width="100%">
        <PhotosListWithUpload photosURLs={photosURLs} setPhotosUrls={setPhotosURLs} />

        <TextField label={t('areas.fields.name')} {...register('text')} disabled />
        <TextField label={t('areas.fields.location')} {...register('location')} />
      </Stack>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteCableRoute}>
          {isLoadingDeleteCableRoute ? <CircularProgress size={16} /> : t('general.delete')}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingCableRoute}>
          {isLoadingCableRoute ? <CircularProgress size={16} /> : t('general.save')}
        </Button>
      </Grid>
    </Stack>
  );
};
