import React from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useQueryClient } from 'react-query';
import { useBoolean } from 'usehooks-ts';
import { EditFiberDialog } from '../../../fibers/components/components/EditFiberDialog';
import { AvailableOutletTypes } from '../../../outlets/AvailiableOutletTypes';
import { EditTPForm } from '../../../fibers/components/components/EditTPForm';
import { useTranslation } from '../../../../common/hooks/useTranslation';
import { TwistedPair } from '../../../outlets/TPOutlet';
import { Outlet } from '../../../outlets/Outlet';

export const EditFiberButton = ({
  propertyId,
  outletId,
  twistedPair,
}: {
  twistedPair: TwistedPair;
  outletId: Outlet['id'];
  propertyId: number;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { value: isEditFiberOpen, setValue: setIsEditFiberOpen } = useBoolean(false);
  const handleEditFiberSuccess = () => {
    queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
    queryClient.invalidateQueries(['propertyConnection', propertyId]);
    queryClient.invalidateQueries(['allPremises', propertyId]);
  };
  return (
    <>
      <EditFiberDialog
        isOpen={isEditFiberOpen}
        onClose={() => {
          setIsEditFiberOpen(false);
        }}
        outletType={AvailableOutletTypes.TPOUTLET}
      >
        {twistedPair && (
          <EditTPForm
            fiber={twistedPair}
            outletId={outletId}
            propertyId={propertyId!}
            onSuccess={() => {
              setIsEditFiberOpen(false);
              handleEditFiberSuccess();
            }}
          />
        )}
      </EditFiberDialog>
      <Button
        variant="text"
        color="primary"
        onClick={() => {
          setIsEditFiberOpen(true);
        }}
      >
        <EditIcon /> {t('tp.edit.button')}
      </Button>
    </>
  );
};
