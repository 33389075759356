import { FC, useState } from 'react';
import { PhotosField } from './PhotosField/PhotosField';
import { Lambda } from '../../../common/types';
import { useAzureObjectPhotoDelete } from '../hooks/useAzureObjectPhotoDelete';
import { PhotoDialogs } from '../../../common/components/PhotoDialogs/PhotoDialogs';

type Props = {
  photosURLs: ReadonlyArray<string>;
  setPhotosUrls: Lambda<ReadonlyArray<string>, void>;
};

export const PhotosListWithUpload: FC<Props> = ({ photosURLs, setPhotosUrls }) => {
  const [isConfirmationWindowOpen, setIsConfirmationWindowOpen] = useState(false);
  const [enlargedPhotoUrl, setEnlargedPhotoUrl] = useState<null | string>(null);
  const [urlToDelete, setUrlToDelete] = useState<string | null>(null);
  const { deletePhoto, isDeletingPhoto } = useAzureObjectPhotoDelete();

  const handleEndDeleting = () => {
    setUrlToDelete(null);
    setIsConfirmationWindowOpen(false);
    if (enlargedPhotoUrl) {
      setEnlargedPhotoUrl(null);
    }
  };
  const handleDelete = async () => {
    if (urlToDelete === null) return;
    try {
      await deletePhoto(urlToDelete);
      setPhotosUrls(photosURLs.filter((photoUrl) => photoUrl !== urlToDelete));
    } finally {
      handleEndDeleting();
    }
  };

  const handleInitDelete = (photoUrl: string) => {
    setUrlToDelete(photoUrl);
    setIsConfirmationWindowOpen(true);
  };

  const openEnlargedPhotoDialog = (photoUrl: string) => {
    setUrlToDelete(photoUrl);
    setEnlargedPhotoUrl(photoUrl);
  };

  const closeEnlargedPhotoDialog = () => {
    setUrlToDelete(null);
    setEnlargedPhotoUrl(null);
  };

  return (
    <>
      <PhotoDialogs
        isConfirmationWindowOpen={isConfirmationWindowOpen}
        isDeletingPhoto={isDeletingPhoto}
        setIsConfirmationWindowOpen={setIsConfirmationWindowOpen}
        onDelete={handleDelete}
        enlargedPhotoUrl={enlargedPhotoUrl}
        onCloseEnlargedPhotoDialog={closeEnlargedPhotoDialog}
      />
      <PhotosField
        value={photosURLs}
        onChange={setPhotosUrls}
        onDelete={handleInitDelete}
        onItemClick={openEnlargedPhotoDialog}
      />
    </>
  );
};
