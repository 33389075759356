import { G } from '@mobily/ts-belt';
import { Box, List, ListItem } from '@mui/material';
import { Lambda } from 'common/types';
import React, { FC } from 'react';
import { ImagePicker } from '../ImagePicker';
import { DeletePhotoButton } from '../../../../common/components/DeletePhotoButton/DeletePhotoButton';

export const PhotosField: FC<{
  value: ReadonlyArray<string>;
  onChange: Lambda<ReadonlyArray<string>, void>;
  onItemClick?: Lambda<string, void>;
  onDelete?: (imageUrl: string) => void;
}> = ({ value, onChange, onItemClick, onDelete }) => {
  const onDeletePhoto = (e: React.MouseEvent<HTMLButtonElement>, photoUrl: string) => {
    e.stopPropagation();
    onDelete?.(photoUrl);
  };

  return (
    <List sx={{ display: 'flex', gap: 2, p: 0, flexWrap: 'wrap' }}>
      {value.map((photoUrl, index) => (
        <ListItem
          key={`incoming-fiber-${photoUrl}}`}
          sx={{ p: 0, flex: '0 0', cursor: G.isNotNullable(onItemClick) ? 'pointer' : 'unset' }}
          onClick={() => {
            if (G.isNotNullable(onItemClick)) {
              onItemClick(photoUrl);
            }
          }}
        >
          <Box width={96} height={96} sx={{ position: 'relative' }}>
            <Box component="img" src={photoUrl} alt={`incoming-fiber-image-${index}`} width="100%" height="100%" />
            <DeletePhotoButton handleInitDelete={(e) => onDeletePhoto(e, photoUrl)} />
          </Box>
        </ListItem>
      ))}
      <ListItem sx={{ p: 0, flex: '0 0' }}>
        <ImagePicker
          onChange={(imageUrl) => {
            onChange([...value, imageUrl]);
          }}
        />
      </ListItem>
    </List>
  );
};
